<template>
  <loading-component
    v-if="loading"
  />
  <error-component
    v-else-if="error"
    :error="error"
  />

  <b-card
    v-else
    no-body
  >
    <date-range-filter
      class="m-2"
      show-checkbox-options
    />
    <app-account-form title="공통" />

    <app-account-form title="지면" />

    <app-account-form title="캠페인" />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import AppAccountForm from '@/views/layouts/AppAccountForm.vue'
import useAccountTask from '@/views/utils/task/useAccountTask'
import DateRangeFilter from '@/views/components/table/DateRangeFilter.vue'
import { provide, ref } from '@vue/composition-api'
import useDate from '@/views/utils/useDate'

export default {
  components: {
    DateRangeFilter,
    BCard,
    AppAccountForm,
  },
  setup() {
    const {
      loading,
      error,
    } = useAccountTask()

    const { getDateRangeFromToday, dateRangeConjunction } = useDate()
    const defaultDateRange = getDateRangeFromToday(30, dateRangeConjunction)
    const dateFilter = ref(defaultDateRange)
    // provide date filter data for DateRangeFilter component
    provide('dateFilter', dateFilter)
    provide('defaultDateRange', defaultDateRange)

    return {
      loading,
      error,
    }
  },
}
</script>
